import { Button, Icon, IconButton } from '@just-ai/just-ui';
import { Dispatch, memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import DomPurify from 'dompurify';
import {
  AudioMessage,
  ButtonsMessage,
  FileMessage,
  FileMessageDto,
  ImageMessage,
  Intent,
  Message,
  PrompterMessageType,
  TextMessage,
  VideoMessage,
} from '@just-ai/aimychat-shared/dist/api/client/websocket';
import { useOperatorDataContext } from '../../OperatorDataContext';
import { PrompterProps } from './PrompterFooter';

function sanitizeText(text: string) {
  return DomPurify.sanitize(text, { USE_PROFILES: { html: true } });
}

type MessageCustom = Message | TextMessage | ImageMessage | FileMessage | ButtonsMessage | AudioMessage | VideoMessage;

type Props = PrompterProps & {
  messages: MessageCustom[];
  intent?: Intent;
  isContextPrompter?: boolean;
  callback?: () => void;
  changeRequest?: (text: string) => void;
  setCurrentHistoryIndex?: Dispatch<SetStateAction<number | undefined>>;
};

export const PrompterMessage = memo(
  ({ messages, intent, isContextPrompter, sendMessage, callback, changeRequest, setCurrentHistoryIndex }: Props) => {
    const { setMessage } = useOperatorDataContext();
    const textMessage = useMemo(
      () =>
        messages
          .map(message => ('text' in message ? message.text : ''))
          .filter(x => x)
          .join('\n'),
      [messages]
    );

    const visibleFilesCount = isContextPrompter ? 10 : 4;

    const chooseButton = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        if (changeRequest) {
          changeRequest(e.currentTarget.name);
        }
      },
      [changeRequest]
    );

    const attachments = useMemo(
      () =>
        messages
          .map((message, index) => {
            if ('buttons' in message && !isContextPrompter) {
              return (
                <div key={`buttons_${index}`} className='ButtonsWrapper'>
                  {message.buttons.map(title => (
                    <Button
                      className='Button'
                      name={title}
                      onClick={chooseButton}
                      key={title}
                      color='secondary'
                      outline
                    >
                      {title}
                    </Button>
                  ))}
                </div>
              );
            }

            if (!('url' in message)) {
              return null;
            }

            switch (message.type) {
              case PrompterMessageType.IMAGE:
                return <img className='PrompterImage' key={message.url} src={message.url} alt='icon' />;
              case PrompterMessageType.AUDIO:
                return (
                  <div key={message.url} className='PrompterImage'>
                    <Icon size='lg' name='farMusic' />
                  </div>
                );
              case PrompterMessageType.FILE:
              case PrompterMessageType.TEXT:
                return (
                  <div key={message.url} className='PrompterImage'>
                    <Icon size='lg' name='farFile' />
                  </div>
                );
              case PrompterMessageType.VIDEO:
                return (
                  <div key={message.url} className='PrompterImage VideoWrapper'>
                    <Icon size='lg' name='faPlay' />
                    <video src={message.url} />
                  </div>
                );
              default:
                return null;
            }
          })
          .filter(x => x),
      [chooseButton, isContextPrompter, messages]
    );

    const [hideExtraFilesPrompter, setHideExtraFilesPrompter] = useState(attachments.length > visibleFilesCount);

    const getFiles = useCallback(() => {
      const files: FileMessageDto[] = (
        messages.filter(message => 'url' in message) as (ImageMessage | FileMessage | AudioMessage | VideoMessage)[]
      ).map(({ url, type }) => ({ url, mimeType: type }));

      const mainFileTypes = {
        avi: 'video/x-msvideo',
        bmp: 'image/bmp',
        jpe: 'image/jpeg',
        jpeg: 'image/jpeg',
        jpg: 'image/jpeg',
        mov: 'video/quicktime',
        mp3: 'audio/mpeg',
        mp4: 'video/mp4',
        mpeg: 'video/mpeg',
        mpg: 'video/mpeg',
        png: 'image/png',
        svg: 'image/svg+xml',
        svgz: 'image/svg+xml',
        txt: 'text/plain',
        wav: 'audio/x-wav',
        wmv: 'video/x-ms-wmv',
      };

      return Promise.all(
        files.map(async file => {
          const blob = await (await fetch(file.url, { mode: 'no-cors' })).blob();
          const extension = file.url.split('.').pop() || blob.type;
          const type = mainFileTypes[extension];
          const fileFromBlob = new File([blob], file.url.split('/').pop() || 'file', {
            type,
          });
          return {
            ...file,
            name: fileFromBlob.name,
            mimeType: file.mimeType === PrompterMessageType.VIDEO ? 'video/mp4' : type,
          };
        })
      ).catch(error => {
        console.error(error);
        return [];
      });
    }, [messages]);

    const handleSendMessage = useCallback(async () => {
      const files = await getFiles();
      sendMessage(textMessage, files);
    }, [getFiles, sendMessage, textMessage]);

    const sendPrompterMessage = useCallback(async () => {
      const files = await getFiles();
      setMessage({ text: textMessage, files: files.length ? files : undefined });
      if (setCurrentHistoryIndex) {
        setCurrentHistoryIndex(undefined);
      }
      if (callback) {
        callback();
      }
    }, [callback, getFiles, setCurrentHistoryIndex, setMessage, textMessage]);

    const description = intent?.name ? (
      <p>
        <small>
          <b data-test-id='Prompter.Intent.Percent' className='Prompter__info__percent'>
            {((intent?.confidence || 0) * 100).toFixed()}%
          </b>
          <b data-test-id='Prompter.Intent.Name'>{intent?.name}</b>
        </small>
      </p>
    ) : null;

    const attachmentsBlock = attachments.length ? (
      <div className='Attachments'>
        {hideExtraFilesPrompter ? attachments.slice(0, visibleFilesCount) : attachments}
        {attachments.length && hideExtraFilesPrompter && (
          <div
            className='PrompterImage'
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setHideExtraFilesPrompter(false);
            }}
          >
            <p>
              <b>{attachments.length - visibleFilesCount}+</b>
            </p>
          </div>
        )}
      </div>
    ) : null;

    if (isContextPrompter) {
      return (
        <div className='Prompter' key={`prompt_${intent?.id}`}>
          <div className='Prompter__info' onClick={sendPrompterMessage}>
            {description}
            <div
              data-test-id='Prompter.Text'
              className='Prompter__text'
              dangerouslySetInnerHTML={{
                __html: sanitizeText(textMessage.replaceAll('\n', '<br>')),
              }}
              title={textMessage.replaceAll('\n', '<br>')}
            />
            {attachmentsBlock}
          </div>

          <IconButton
            outline
            id='sent-prompter-button'
            data-test-id='Chat.MessageForm.Promtper.Sent.Button'
            name='farArrowRight'
            onClick={handleSendMessage}
          />
        </div>
      );
    }

    return (
      <div className='Prompter' key={`prompt_${intent?.id}`} onClick={sendPrompterMessage}>
        {description}
        <div dangerouslySetInnerHTML={{ __html: sanitizeText(textMessage.replaceAll('\n', '<br>')) }} />
        {attachmentsBlock}
      </div>
    );
  }
);
