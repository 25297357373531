import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createRef, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { getOptionsToView, makeArrayFromValue, Timeout } from '../utils';
import { defaultMessages } from '../types';
import { DefaultListItem, getSelectListItem } from '../JustSelectItem';
import '../JustSelect.scss';
import { JustSelectContainer } from './JustSelectContainer';
import { JustSelectList } from './JustSelectList';
import ErrorText from '../../ErrorText';
import { Input as RSInput } from 'reactstrap';
import { DropDownHint } from '../../DropDownHint';
export class JustSelectState {
    constructor() {
        this.focused = false;
        this.opened = false;
        this.searchValue = '';
        this.focusedElement = 0;
        this.submittedSearchOptions = [];
        this.selected = [];
        this.ticker = 0;
    }
}
export default class JustSelectMultiple extends PureComponent {
    constructor() {
        super(...arguments);
        this.state = new JustSelectState();
        this.selectWrapperRef = createRef();
        this.ListRef = createRef();
        this.submitTimeout = null;
        this.interval = null;
        this.scrollbarContainer = null;
        this.selfStyles = null;
        this.getInitialOpenState = () => {
            if (this.props.hasOwnProperty('open')) {
                return this.props.open;
            }
            if (this.props.hasOwnProperty('autoFocus')) {
                return this.props.autoFocus;
            }
            return false;
        };
        this.onChangeHandler = () => {
            const { onChange } = this.props;
            onChange === null || onChange === void 0 ? void 0 : onChange(this.state.selected.length > 0 ? this.state.selected.map(selectionItem => selectionItem['value']) : null);
        };
        this.onChangeNative = (event) => {
            const { onChange } = this.props;
            //@ts-ignore
            onChange === null || onChange === void 0 ? void 0 : onChange([event.target.value]);
        };
        this.setValue = (value, prevValue) => {
            const { options = [] } = this.props;
            if ((value && !prevValue) || value !== prevValue) {
                const valueAsArray = makeArrayFromValue(value);
                const found = valueAsArray
                    .map(valueOption => options.find(option => option.value === valueOption))
                    .filter(option => !(option === undefined));
                if (found.length > 0) {
                    //@ts-ignore filter above save from undefined;
                    this.setState({ selected: found });
                    if (valueAsArray.length !== found.length) {
                        console.error(`There is some options missing with some value ${valueAsArray.join(', ')}`);
                    }
                }
                else if (valueAsArray.length > 0) {
                    this.setState({ selected: [] });
                }
            }
        };
        this.onListAppeared = listElement => {
            this.ListRef.current = listElement !== null && listElement !== void 0 ? listElement : null;
            if (listElement)
                this.calculateDropdownListStyles(true);
        };
        this.calculateDropdownListStyles = (force) => {
            var _a, _b;
            let { dropdownPosition, listAutoPosition } = this.props;
            if (this.selectWrapperRef.current && this.props.position === 'fixed') {
                const rect = this.selectWrapperRef.current.getBoundingClientRect();
                if (listAutoPosition || !dropdownPosition) {
                    const viewportHeight = window.innerHeight;
                    const listRect = (_a = this.ListRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
                    const listHeight = (_b = listRect === null || listRect === void 0 ? void 0 : listRect.height) !== null && _b !== void 0 ? _b : 0;
                    const dropdownBottomEndPosition = rect.top + rect.height + listHeight;
                    const dropdownTopStartPosition = rect.top - listHeight;
                    const bottomOverlayDegree = dropdownBottomEndPosition - viewportHeight;
                    const topOverlayDegree = -dropdownTopStartPosition;
                    const isBottomOverlay = bottomOverlayDegree < 0;
                    dropdownPosition = isBottomOverlay && bottomOverlayDegree <= topOverlayDegree ? 'bottom' : 'top';
                }
                if (dropdownPosition === 'bottom') {
                    this.selfStyles = {
                        position: 'fixed',
                        top: rect.top + rect.height,
                        width: rect.width,
                        left: rect.left,
                    };
                }
                else {
                    this.selfStyles = {
                        position: 'fixed',
                        top: rect.top,
                        width: rect.width,
                        left: rect.left,
                        transform: 'translateY(calc(-100% - 4px))',
                    };
                }
                if (this.props.adaptiveDropdownWidth) {
                    this.selfStyles.minWidth = this.selfStyles.width;
                    this.selfStyles.width = 'auto';
                }
            }
            else {
                this.selfStyles = {};
            }
            if (force) {
                this.forceUpdate();
            }
        };
        this.handleFocus = () => {
            if (!this.props.listAutoPosition)
                this.calculateDropdownListStyles();
            this.setState({
                focused: true,
                opened: this.props.hasOwnProperty('open') ? this.props.open : true,
            });
        };
        this.handleBlur = (e) => {
            setTimeout(() => {
                const target = document.activeElement !== document.body ? document.activeElement : e.target;
                if (this.selectWrapperRef &&
                    this.selectWrapperRef.current &&
                    !this.selectWrapperRef.current.contains(target) &&
                    this.ListRef &&
                    this.ListRef.current &&
                    !this.ListRef.current.contains(target)) {
                    this.setState({
                        focused: false,
                        opened: false,
                        submittedSearchOptions: [],
                        searchValue: '',
                    });
                }
            }, 0);
        };
        this.submitSearch = () => {
            const { searchValue } = this.state;
            const { options = [] } = this.props;
            let foundOptions = options === null || options === void 0 ? void 0 : options.filter(option => searchValue.length > 0
                ? option.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                : true);
            if (foundOptions) {
                this.setState({
                    submittedSearchOptions: foundOptions,
                    focusedElement: 0,
                });
            }
        };
        this.setSearchValueHandler = (event) => {
            const { onInput } = this.props;
            const value = event.target.value;
            onInput && onInput(value);
            this.setState({
                searchValue: value,
            });
        };
        this.onSelectHandler = (value) => {
            const { options = [], maxSelected = Infinity } = this.props;
            const { selected } = this.state;
            const optionIndex = options.findIndex(optionsItem => optionsItem.value === value);
            const found = selected.findIndex(select => select.value === value);
            if (optionIndex === -1) {
                console.error(`Option Not Found`, options, value);
                return;
            }
            const option = options[optionIndex];
            if (option && !option.disabled) {
                if (option && found === -1) {
                    if (maxSelected > selected.length) {
                        let newSelected = [...selected];
                        newSelected.push(option);
                        this.setState({
                            selected: newSelected,
                        }, () => {
                            if (!this.props.listAutoPosition)
                                this.calculateDropdownListStyles(true);
                            this.onChangeHandler();
                        });
                    }
                }
            }
        };
        this.onHoverHandler = (index) => () => this.setState({ focusedElement: index });
        this.setScroll = (focusIndex, prevFocusedIndex) => {
            const { selectItemHeight = 37 } = this.props;
            if (this.state.opened && this.scrollbarContainer) {
                let focusedHtmlElement = Array.from(this.scrollbarContainer.children).filter(item => !item.className.includes('ps__'))[focusIndex];
                if (focusedHtmlElement) {
                    if (focusIndex - prevFocusedIndex ===
                        1 //DOWN
                    ) {
                        if (this.scrollbarContainer.scrollTop + this.scrollbarContainer.offsetHeight - focusedHtmlElement.offsetTop <=
                            selectItemHeight) {
                            this.scrollbarContainer.scrollTop += selectItemHeight;
                        }
                    }
                    else {
                        if (prevFocusedIndex - focusIndex ===
                            1 //UP
                        ) {
                            if (this.scrollbarContainer.scrollTop > focusedHtmlElement.offsetTop) {
                                this.scrollbarContainer.scrollTop = selectItemHeight * focusIndex;
                            }
                        }
                        else {
                            this.scrollbarContainer.scrollTop = focusedHtmlElement.offsetTop;
                        }
                    }
                }
            }
        };
        this.handleKeyDown = (event) => {
            const { submittedSearchOptions, selected, focusedElement, searchValue } = this.state;
            const { options = [] } = this.props;
            const optionsPureOrFiltered = getOptionsToView(submittedSearchOptions, searchValue, options, selected, true);
            const optionsPureOrFilteredLength = optionsPureOrFiltered.length - 1;
            if (event.key === 'ArrowUp') {
                let newFocusedElement = focusedElement - 1;
                if (newFocusedElement < 0) {
                    this.setScroll(optionsPureOrFilteredLength, newFocusedElement);
                    this.setState({
                        focusedElement: optionsPureOrFilteredLength,
                    });
                }
                else {
                    this.setScroll(newFocusedElement, focusedElement);
                    this.setState({
                        focusedElement: newFocusedElement,
                    });
                }
            }
            if (event.key === 'ArrowDown') {
                let newFocusedElement = focusedElement + 1;
                if (newFocusedElement > optionsPureOrFilteredLength) {
                    this.setScroll(0, focusedElement);
                    this.setState({
                        focusedElement: 0,
                    });
                }
                else {
                    this.setScroll(newFocusedElement, focusedElement);
                    this.setState({
                        focusedElement: newFocusedElement,
                    });
                }
            }
            if (event.key === 'Enter') {
                if (!(event.shiftKey || event.ctrlKey || event.metaKey || event.altKey)) {
                    event.stopPropagation();
                }
                event.preventDefault();
                if (optionsPureOrFiltered[focusedElement]) {
                    this.onSelectHandler(optionsPureOrFiltered[focusedElement].value);
                    this.setState({ searchValue: '' });
                }
            }
            if (event.key === 'Escape') {
                this.close();
            }
        };
        this.deleteSelection = (option) => {
            let newSelected = [...this.state.selected];
            let selectionIndex = newSelected.findIndex(selectedOption => (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) === option.value);
            if (selectionIndex > -1) {
                newSelected.splice(selectionIndex, 1);
            }
            this.setState({
                selected: newSelected,
            }, () => {
                this.onChangeHandler();
            });
        };
        this.backSpaceDeleteSelected = (event) => {
            const { selected } = this.state;
            const value = event.target.value;
            if (event.key === 'Backspace' && selected.length > 0 && value.length === 0) {
                const newSelected = [...selected];
                newSelected.splice(newSelected.length - 1, 1);
                this.setState({
                    selected: newSelected,
                }, () => {
                    this.onChangeHandler();
                });
            }
        };
        this.getScrollBar = (target) => {
            //@ts-ignore
            this.scrollbarContainer = target;
        };
        this.close = () => {
            const { open, onInput } = this.props;
            this.setState({
                focused: false,
                opened: open || false,
                searchValue: '',
            });
            onInput && onInput('');
        };
        this.cleanup = () => {
            this.setState({
                selected: [],
            }, () => {
                var _a, _b;
                this.onChangeHandler();
                (_b = (_a = this.props).onInput) === null || _b === void 0 ? void 0 : _b.call(_a, '');
            });
        };
        this.renderDropDown = () => {
            const { focused, opened, searchValue, selected, submittedSearchOptions, focusedElement } = this.state;
            const { optionComponent = DefaultListItem, optionComponentProps, maxSelected = Infinity, options = [], messages = defaultMessages, open, listStyles, dropdownPosition = 'bottom', classNameForList, size, isLoading = false, } = this.props;
            const ComponentSelectItem = getSelectListItem(optionComponent, this.onSelectHandler, optionComponentProps);
            const innerOptions = getOptionsToView(submittedSearchOptions, searchValue, options, selected, true);
            return (_jsx("div", { style: { position: 'relative' }, children: _jsx(JustSelectList, { "data-test-id": this.props['data-test-id'], submitTimeout: this.submitTimeout, listStyles: listStyles, selfStyles: this.selfStyles, maxSelected: maxSelected, selected: selected, getScrollBar: this.getScrollBar, innerOptions: innerOptions, options: options, searchValue: searchValue, focusedElement: focusedElement, onSelectHandler: this.onSelectHandler, onHoverHandler: this.onHoverHandler, messages: messages, ComponentSelectItem: ComponentSelectItem, focused: focused, opened: opened || open, dropdownPosition: dropdownPosition, position: this.props.position, ref: this.onListAppeared, classNameForList: classNameForList, size: size, isLoading: isLoading }) }));
        };
    }
    static getDerivedStateFromProps(props, state) {
        return Object.assign(Object.assign({}, state), { opened: props.hasOwnProperty('open') ? props.open : state.opened });
    }
    componentDidMount() {
        this.setValue(this.props.defaultValue || this.props.value);
        this.setState({
            opened: this.getInitialOpenState(),
        });
        if (this.props.getRef && this.selectWrapperRef.current) {
            this.props.getRef(this.selectWrapperRef.current);
        }
    }
    componentDidUpdate(prevProps, prevState) {
        var _a, _b;
        const { options = [], onOpen, value = [], searchDebounce = 300 } = this.props;
        const { opened, selected, searchValue } = this.state;
        if (opened) {
            if (!this.props.listAutoPosition)
                this.calculateDropdownListStyles();
            this.selectWrapperRef.current && this.selectWrapperRef.current.addEventListener('keydown', this.handleKeyDown);
        }
        if (prevState.opened && !opened) {
            this.selectWrapperRef.current && this.selectWrapperRef.current.removeEventListener('keydown', this.handleKeyDown);
        }
        if (prevState.opened !== opened) {
            onOpen && onOpen(opened);
        }
        this.setValue(value, prevProps.value);
        if (searchValue && options !== prevProps.options) {
            (_a = this.submitTimeout) === null || _a === void 0 ? void 0 : _a.clear();
            this.submitTimeout = new Timeout(this.submitSearch, 0, () => this.setState({ ticker: this.state.ticker + 1 }));
        }
        if (JSON.stringify(selected) !== JSON.stringify(prevState.selected) &&
            JSON.stringify(value) !== JSON.stringify((selected || []).map(select => select.value))) {
            if (this.props.position === 'fixed' && !this.props.listAutoPosition) {
                this.calculateDropdownListStyles();
            }
        }
        if (searchValue !== prevState.searchValue) {
            (_b = this.submitTimeout) === null || _b === void 0 ? void 0 : _b.clear();
            this.submitTimeout = new Timeout(this.submitSearch, searchDebounce, () => this.setState({ ticker: this.state.ticker + 1 }));
        }
        if (this.props.getRef && this.selectWrapperRef.current) {
            this.props.getRef(this.selectWrapperRef.current);
        }
        if (this.props.options !== prevProps.options && value === prevProps.value) {
            this.setValue(value);
        }
    }
    componentWillUnmount() {
        var _a;
        this.selectWrapperRef.current && this.selectWrapperRef.current.removeEventListener('keydown', this.handleKeyDown);
        this.interval && clearInterval(this.interval);
        (_a = this.submitTimeout) === null || _a === void 0 ? void 0 : _a.clear();
    }
    render() {
        const { focused, opened, searchValue, selected } = this.state;
        const { autoFocus, fullWidth, inputPlaceholder, selectedItemComponent = DefaultListItem, selectedItemComponentProps, open, invalid, iconArrowShow, closeable = true, className, size, 'data-test-id': dataTestId, withoutSearch, hideMultipleSelectedOptions, showSystemSelect, options, name, id, } = this.props;
        if (showSystemSelect) {
            return (_jsx(RSInput, { type: 'select', "data-test-id": dataTestId, name: name, bsSize: size, id: id, onChange: this.onChangeNative, value: undefined, invalid: invalid, children: options === null || options === void 0 ? void 0 : options.map(option => (_jsx("option", { value: option.value, disabled: option.disabled, children: option.label }, option.value))) }));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: classNames('just-select-wrapper', { xs: size === 'xs' }, { focused: focused }, { opened: opened || open }, { fullWidth: fullWidth }, className), "data-test-id": dataTestId, onBlur: this.handleBlur, role: 'select', children: [_jsxs("div", { ref: this.selectWrapperRef, className: 'just-select-wrapper_container', children: [_jsx(JustSelectContainer, { dataTestId: dataTestId, focused: focused, withoutSearch: withoutSearch, searchValue: searchValue, iconLeft: this.props.iconLeft, onChange: this.setSearchValueHandler, onFocus: this.handleFocus, backSpaceDeleteSelected: this.backSpaceDeleteSelected, selected: selected, autoFocus: autoFocus, selectedItemComponent: selectedItemComponent, selectedItemComponentProps: selectedItemComponentProps, inputPlaceholder: inputPlaceholder, deleteSelectedItem: this.deleteSelection, cleanupSelected: this.cleanup, invalid: invalid || !!this.props.errorText, iconArrowShow: iconArrowShow, disabled: this.props.disabled, size: size, dropdownIconName: this.props.dropdownIconName, hideMultipleSelectedOptions: hideMultipleSelectedOptions, closeable: closeable, onValueClick: this.props.onValueClick }), this.props.rightRenderSlot && this.props.rightRenderSlot()] }), this.props.position === 'fixed' &&
                            closeable &&
                            (opened || open) &&
                            ReactDOM.createPortal(_jsx("div", { className: 'just-select-backdrop opened', onClick: this.close }), document.body), (opened || open) &&
                            (this.props.position === 'fixed'
                                ? ReactDOM.createPortal(this.renderDropDown(), document.body)
                                : this.renderDropDown()), this.props.errorText && _jsx(ErrorText, { text: this.props.errorText }), this.props.hint && !this.props.errorText && (_jsx("span", { className: 'hint', dangerouslySetInnerHTML: { __html: this.props.hint } }))] }), (opened || open) && closeable && this.props.position !== 'fixed' && (_jsx("div", { className: 'just-select-backdrop', onClick: this.close })), this.props.dropDownHint ? _jsx(DropDownHint, { dropDownHint: this.props.dropDownHint }) : null] }));
    }
}
